import store from '@/store';

export default {
  mounted(el, binding) {
    const { value } = binding;
    const userPermissions = store.getters['auth/currentUser']?.role?.permissions || [];

    if (value) {
      const hasPermission = Array.isArray(value)
        ? value.some(permission => userPermissions.includes(permission))
        : userPermissions.includes(value);

      if (!hasPermission) {
        el.style.display = 'none';
      }
    }
  },
  updated(el, binding) {
    const { value } = binding;
    const userPermissions = store.getters['auth/currentUser']?.role?.permissions || [];

    if (value) {
      const hasPermission = Array.isArray(value)
        ? value.some(permission => userPermissions.includes(permission))
        : userPermissions.includes(value);

      el.style.display = hasPermission ? '' : 'none';
    }
  }
}; 