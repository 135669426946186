import Vue from 'vue';
import VueRouter from 'vue-router';
import { DASHBOARD_PERMISSIONS } from '@/constants/permissions';
import { checkDashboardAccess } from '../utils/permissionHelper';
import { store } from '@/store';

const LoginView = () => import('../views/auth/Login.vue');
const ForgotPassword = () => import('../views/auth/ForgotPassword.vue');
const ResetPassword = () => import('../views/auth/ResetPassword.vue');
const SetPassword = () => import('../views/auth/SetPassword.vue');
const EditProfile = () => import('../views/dashboard/EditProfile.vue');
const ChangePassword = () => import('../views/dashboard/ChangePassword.vue');
const DashboardLayout = () => import('../views/dashboard/DashboardLayout.vue');
const AllStaff = () => import('../views/dashboard/staff/AllStaff.vue');
const AllLoans = () => import('../views/dashboard/loans/AllLoans.vue');
const LoansAwaitingAction = () =>
  import('../views/dashboard/loans/LoansAwaitingAction.vue');
const KongaLoans = () => import('../views/dashboard/loans/KongaLoans.vue');
const ProcessKongaCommissions = () =>
  import('../views/dashboard/loans/ProcessKongaCommissions.vue');
const AllInvestments = () =>
  import('../views/dashboard/investments/AllInvestments.vue');
const InvestmentDetails = () =>
  import('../views/dashboard/investments/InvestmentDetails.vue');
const AddDepositForInvestment = () =>
  import('../views/dashboard/investments/AddDeposit.vue');
const AddMaturePayoutForInvestment = () =>
  import('../views/dashboard/investments/AddPayout.vue');
const AddActivePayoutForInvestment = () =>
  import('../views/dashboard/investments/AddActiveInvestmentPayout.vue');
const AddInvestment = () =>
  import('../views/dashboard/investments/AddInvestment.vue');
const AddInvestmentFromReinvestment = () =>
  import('../views/dashboard/investments/AddInvestmentFromReinvestment.vue');
const AddPayoutForInterest = () =>
  import('../views/dashboard/investments/AddPayoutForInterest.vue');
const AllCompanies = () =>
  import('../views/dashboard/companies/AllCompanies.vue');
const PendingApproval = () =>
  import('../views/dashboard/companies/PendingApproval.vue');
const AllCustomers = () =>
  import('../views/dashboard/customers/AllCustomers.vue');
const CustomersPendingVerification = () =>
  import('../views/dashboard/customers/CustomersPendingVerification.vue');
const StaffGroupList = () =>
  import('../views/dashboard/staff/StaffGroupList.vue');
const AddStaff = () => import('../views/dashboard/staff/AddStaff.vue');
const AllRoles = () =>
  import('../views/dashboard/staff/AllRoles.vue');
const AddRole = () => import('../views/dashboard/staff/AddRole.vue');
const SingleRole = () => import('../views/dashboard/staff/SingleRole.vue');
const RoleSettings = () => import('../views/dashboard/staff/RoleSettings.vue');
const AddCompany = () => import('../views/dashboard/companies/AddCompany.vue');
const AddCustomer = () =>
  import('../views/dashboard/customers/AddCustomer.vue');
const EditCustomer = () =>
  import('../views/dashboard/customers/EditCustomer.vue');
const CompanyProfile = () =>
  import('../views/dashboard/companies/CompanyProfile.vue');
const CompanySettings = () =>
  import('../views/dashboard/companies/CompanySettings.vue');
const ReviewWithdrawal = () =>
  import('../views/dashboard/companies/ReviewWithdrawal.vue');
const InitiateWithdrawal = () =>
  import('../views/dashboard/companies/InitiateWithdrawal.vue');
const CustomerProfile = () =>
  import('../views/dashboard/customers/CustomerProfile.vue');
const SingleStaff = () => import('../views/dashboard/staff/SingleStaff.vue');
const StaffAccountSettings = () =>
  import('../views/dashboard/staff/AccountSettings.vue');
const LoanDetails = () => import('../views/dashboard/loans/LoanDetails.vue');
const LoanTransactionDetails = () =>
  import('../views/dashboard/loans/LoanTransactionDetails.vue');
const PayoutDetails = () =>
  import('../views/dashboard/investments/PayoutDetails.vue');
const LoanRepaymentDetails = () =>
  import('../views/dashboard/loans/LoanRepaymentDetails.vue');
const InvestmentTransactionDetails = () =>
  import('../views/dashboard/investments/TransactionDetails.vue');
const ReviewLoanExtension = () =>
  import('../views/dashboard/loans/ReviewLoanExtension.vue');
const ReviewLoanAmendment = () =>
  import('../views/dashboard/loans/ReviewLoanAmendment.vue');

const LoanRestructure = () =>
  import('../views/dashboard/loans/LoanRestructure.vue');
const AmendLoan = () => import('../views/dashboard/loans/AmendLoan.vue');
const AddDisbursementTransaction = () =>
  import('../views/dashboard/loans/AddDisbursementTransaction.vue');
const AddRepaymentTransaction = () =>
  import('../views/dashboard/loans/AddRepaymentTransaction.vue');
const ChangeLoanSettings = () =>
  import('../views/dashboard/loans/ChangeLoanSettings.vue');
const LPOLoan = () =>
  import('../views/dashboard/loans/ApplyForLoan/LPOLoan.vue');
const IDFLoan = () =>
  import('../views/dashboard/loans/ApplyForLoan/IDFLoan.vue');
const WCLoan = () => import('../views/dashboard/loans/ApplyForLoan/WCLoan.vue');
const LFLoan = () => import('../views/dashboard/loans/ApplyForLoan/LFLoan.vue');
const AddCreditTransaction = () =>
  import('../views/dashboard/companies/AddCreditTransaction.vue');
const AddDebitTransaction = () =>
  import('../views/dashboard/companies/AddDebitTransaction.vue');
const OtherLoans = () => import('../views/dashboard/loans/OtherLoans.vue');
const FlwPaymentLogs = () =>
  import('../views/dashboard/payments/FlwPaymentLogs.vue');
const WemaPaymentLogs = () =>
  import('../views/dashboard/payments/WemaPaymentLogs.vue');
const TransactionDetails = () =>
  import('../views/dashboard/payments/TransactionDetails.vue');
const GenerateCompanyStatement = () =>
  import('../views/dashboard/payments/GenerateCompanyStatement.vue');
const OtherLoanDetails = () =>
  import('../views/dashboard/loans/OtherLoanDetails.vue');
const ApproveTransaction = () =>
  import('../views/dashboard/payments/ApproveTransaction.vue');
const EditWCLoanRiskProfile = () =>
  import('../views/dashboard/loans/EditRiskProfile/EditWCLoanRiskProfile.vue');
const EditIDFLoanRiskProfile = () =>
  import('../views/dashboard/loans/EditRiskProfile/EditIDFLoanRiskProfile.vue');
const EditLPOLoanRiskProfile = () =>
  import('../views/dashboard/loans/EditRiskProfile/EditLPOLoanRiskProfile.vue');
const EditLFLoanRiskProfile = () =>
  import('../views/dashboard/loans/EditRiskProfile/EditLFLoanRiskProfile.vue');
const AllPartners = () => import('../views/dashboard/partners/AllPartners.vue');
const AddPartner = () => import('../views/dashboard/partners/AddPartner.vue');
const EditPartner = () => import('../views/dashboard/partners/EditPartner.vue');
const ReviewDomiciliation = () =>
  import('../views/dashboard/partners/ReviewDomiciliation.vue');
const PartnerDetails = () =>
  import('../views/dashboard/partners/PartnerDetails.vue');
const AllCorporates = () => import('../views/dashboard/corporates/AllCorporates.vue');
const AddCorporate = () => import('../views/dashboard/corporates/AddCorporate.vue');
const EditCorporate = () => import('../views/dashboard/corporates/EditCorporate.vue');
const MergeProfiles = () => import('../views/dashboard/corporates/MergeProfiles.vue');
const CorporateProfile = () => import('../views/dashboard/corporates/CorporateProfile.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/set-password',
    name: 'SetPassword',
    component: SetPassword,
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Home',
        component: () => import('@/views/dashboard/HomeView.vue'),
        meta: {
          // requiresAuth: true,
          // permissions: [DASHBOARD_PERMISSIONS.view_dashboard]
        }
      }
    ]
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('@/views/Unauthorized.vue')
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfile,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
  },

  {
    path: '/all-loans',
    name: 'AllLoans',
    component: AllLoans,
  },
  {
    path: '/loans-awaiting-action',
    name: 'LoansAwaitingAction',
    component: LoansAwaitingAction,
  },
  {
    path: '/konga-loans',
    name: 'KongaLoans',
    component: KongaLoans,
  },
  {
    path: '/process-konga-commissions',
    name: 'ProcessKongaCommissions',
    component: ProcessKongaCommissions,
  },
  {
    path: '/all-loans/book-lpo-loan',
    name: 'BookLPOLoan',
    component: LPOLoan,
  },
  {
    path: '/all-loans/book-idf-loan',
    name: 'BookIDFLoan',
    component: IDFLoan,
  },
  {
    path: '/all-loans/book-lf-loan',
    name: 'BookLFLoan',
    component: LFLoan,
  },
  {
    path: '/all-loans/book-wc-loan',
    name: 'BookWCLoan',
    component: WCLoan,
  },
  {
    path: '/all-loans/:id',
    name: 'LoanDetails',
    component: LoanDetails,
  },
  {
    path: '/all-loans/:id/edit-wc-loan-risk-profile',
    name: 'EditWCLoanRiskProfile',
    component: EditWCLoanRiskProfile,
  },
  {
    path: '/all-loans/:id/edit-idf-loan-risk-profile',
    name: 'EditIDFLoanRiskProfile',
    component: EditIDFLoanRiskProfile,
  },
  {
    path: '/all-loans/:id/edit-lf-loan-risk-profile',
    name: 'EditLFLoanRiskProfile',
    component: EditLFLoanRiskProfile,
  },

  {
    path: '/all-loans/:id/edit-lpo-loan-risk-profile',
    name: 'EditLPOLoanRiskProfile',
    component: EditLPOLoanRiskProfile,
  },

  {
    path: '/all-loans/:id/review-loan-extension/:extensionId',
    name: 'ReviewLoanExtension',
    component: ReviewLoanExtension,
  },
  {
    path: '/all-loans/:id/review-loan-amendment/:amendmentId',
    name: 'ReviewLoanAmendment',
    component: ReviewLoanAmendment,
  },

  {
    path: '/all-loans/:id/loan-restructure',
    name: 'LoanRestructure',
    component: LoanRestructure,
  },
  {
    path: '/all-loans/:id/amend-loan',
    name: 'AmendLoan',
    component: AmendLoan,
  },
  {
    path: '/all-loans/:id/add-disbursement-transaction',
    name: 'AddDisbursementTransaction',
    component: AddDisbursementTransaction,
  },
  {
    path: '/all-loans/:id/add-repayment-transaction',
    name: 'AddRepaymentTransaction',
    component: AddRepaymentTransaction,
  },
  {
    path: '/all-loans/:id/change-loan-settings',
    name: 'ChangeLoanSettings',
    component: ChangeLoanSettings,
  },
  {
    path: '/all-loans/:id/loan-transactions/:loanId',
    name: 'LoanTransactionDetails',
    component: LoanTransactionDetails,
  },
  {
    path: '/all-loans/:id/loan-installments/:loanId',
    name: 'LoanRepaymentDetails',
    component: LoanRepaymentDetails,
  },
  {
    path: '/other-loans',
    name: 'OtherLoans',
    component: OtherLoans,
  },
  {
    path: '/other-loans/:id',
    name: 'OtherLoanDetails',
    component: OtherLoanDetails,
  },
  {
    path: '/all-investments',
    name: 'AllInvestments',
    component: AllInvestments,
  },
  {
    path: '/all-investments/ngn-reinvestment',
    name: 'AddNGNInvestmentFromReinvestment',
    component: AddInvestmentFromReinvestment,
    props: { isUSD: false },
  },
  {
    path: '/all-investments/usd-reinvestment',
    name: 'AddUSDInvestmentFromReinvestment',
    component: AddInvestmentFromReinvestment,
    props: { isUSD: true },
  },
  {
    path: '/all-investments/ngn-investment',
    name: 'AddNGNInvestment',
    component: AddInvestment,
    props: { isUSD: false },
  },
  {
    path: '/all/investments/add-usd-investment',
    name: 'AddUSDInvestment',
    component: AddInvestment,
    props: { isUSD: true },
  },
  {
    path: '/all-investments/:id/add-deposit',
    name: 'AddNGNDeposit',
    component: AddDepositForInvestment,
    props: { isUSD: false },
  },
  {
    path: '/all-investments/:id/add-deposit',
    name: 'AddUSDDeposit',
    component: AddDepositForInvestment,
    props: { isUSD: true },
  },
  {
    path: '/all-investments/:id/add-payout',
    name: 'AddNGNPayoutForMatureInvestment',
    component: AddMaturePayoutForInvestment,
    props: { isNGN: true },
  },
  {
    path: '/all-investments/:id/add-payout',
    name: 'AddUSDPayoutForMatureInvestment',
    component: AddMaturePayoutForInvestment,
    props: { isNGN: false },
  },
  {
    path: '/all-investments/:id/active/add-payout',
    name: 'AddNGNPayoutForActiveInvestment',
    component: AddActivePayoutForInvestment,
    props: { isNGN: true },
  },
  {
    path: '/all-investments/:id/active/add-payout',
    name: 'AddUSDPayoutForActiveInvestment',
    component: AddActivePayoutForInvestment,
    props: { isNGN: false },
  },
  {
    path: '/all-investments/:id/partial/add-payout',
    name: 'AddNGNPayoutForInterest',
    component: AddPayoutForInterest,
    props: { isNGN: true },
  },
  {
    path: '/all-investments/:id/partial/add-payout',
    name: 'AddUSDPayoutForInterest',
    component: AddPayoutForInterest,
    props: { isNGN: false },
  },
  {
    path: '/all-investments/:id/payouts/NGN/:payoutId',
    name: 'NGNPayoutDetails',
    component: PayoutDetails,
  },
  {
    path: '/all-investments/:id/payouts/USD/:payoutId',
    name: 'USDPayoutDetails',
    component: PayoutDetails,
  },
  {
    path: '/all-investments/:id',
    name: 'InvestmentDetails',
    component: InvestmentDetails,
  },
  {
    path: '/all-staff',
    name: 'AllStaff',
    component: AllStaff,
  },
  {
    path: 'groups',
    name: 'StaffGroupList',
    component: StaffGroupList,
  },
 
  {
    path: '/all-staff/create',
    name: 'AddStaff',
    component: AddStaff,
  },
  {
    path: '/all-staff/:id',
    name: 'SingleStaff',
    component: SingleStaff,
  },
  {
    path: '/all-staff/:id/account-settings',
    name: 'AccountSettings',
    component: StaffAccountSettings,
  },
  {
    path: '/all-roles',
    name: 'AllRoles',
    component: AllRoles,
  },
  {
    path: '/all-roles/create',
    name: 'AddRole',
    component: AddRole,
  },
  {
    path: '/all-roles/:id',
    name: 'SingleRole',
    component: SingleRole,
  },
  {
    path: '/all-roles/:id/role-settings',
    name: 'RoleSettings',
    component: RoleSettings,
  },
  {
    path: '/all-customers',
    name: 'AllCustomers',
    component: AllCustomers,
  },
  {
    path: '/pending-verification',
    name: 'CustomersPendingVerification',
    component: CustomersPendingVerification,
  },
  {
    path: '/all-customers/create',
    name: 'AddCustomer',
    component: AddCustomer,
  },
  {
    path: '/all-customers/:id',
    name: 'CustomerProfile',
    component: CustomerProfile,
  },
  {
    path: '/all-customers/:id/edit',
    name: 'EditCustomer',
    component: EditCustomer,
  },
  {
    path: '/all-companies',
    name: 'AllCompanies',
    component: AllCompanies,
  },
  {
    path: '/pending-approval',
    name: 'PendingApproval',
    component: PendingApproval,
  },
  {
    path: '/all-companies/:id',
    name: 'CompanyProfile',
    component: CompanyProfile,
  },
  {
    path: '/all-companies/:id/initiate-withdrawal',
    name: 'InitiateWithdrawal',
    component: InitiateWithdrawal,
  },
  {
    path: '/withdrawal/:id/review-withdrawal',
    name: 'ReviewWithdrawal',
    component: ReviewWithdrawal,
  },
  {
    path: 'all-companies/create',
    name: 'AddCompany',
    component: AddCompany,
  },
  {
    path: 'all-companies/:id/company-settings',
    name: 'CompanySettings',
    component: CompanySettings,
  },
  {
    path: 'all-companies/:id/add-credit-transaction/:walletId',
    name: 'AddCreditTransaction',
    component: AddCreditTransaction,
  },
  {
    path: 'all-companies/:id/add-debit-transaction/:walletId',
    name: 'AddDebitTransaction',
    component: AddDebitTransaction,
  },
  {
    path: '/all-partners',
    name: 'AllPartners',
    component: AllPartners,
  },
  {
    path: 'all-partners/create',
    name: 'AddPartner',
    component: AddPartner,
  },
  {
    path: '/all-partners/review-domiciliation/:id',
    name: 'ReviewDomiciliation',
    component: ReviewDomiciliation,
  },
  {
    path: 'all-partners/:id/edit',
    name: 'EditPartner',
    component: EditPartner,
  },
  {
    path: '/all-partners/:id',
    name: 'PartnerDetails',
    component: PartnerDetails,
  },
  {
    path: '/payments/flw-payment-logs',
    name: 'FlwPaymentLogs',
    component: FlwPaymentLogs,
  },
  {
    path: '/payments/wema-payment-logs',
    name: 'WemaPaymentLogs',
    component: WemaPaymentLogs,
  },
  {
    path: '/payments/generate-company-statement',
    name: 'GenerateCompanyStatement',
    component: GenerateCompanyStatement,
  },
  {
    path: '/payments/add-credit-transaction',
    name: 'AddCreditFromPayments',
    component: AddCreditTransaction,
  },
  {
    path: '/payments/add-debit-transaction',
    name: 'AddDebitFromPayments',
    component: AddDebitTransaction,
  },
  {
    path: '/payments/approve-transaction/:transactionId',
    name: 'ApproveTransaction',
    component: ApproveTransaction,
  },
  {
    path: '/payments/logs/:id',
    name: 'TransactionDetails',
    component: TransactionDetails,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // First check if route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isLoggedIn = store.getters['auth/isLoggedIn'];
    
    if (!isLoggedIn) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath }
      });
      return;
    }
    
    // Then check permissions
    // checkDashboardAccess(to, from, next);
    next();
  } else {
    next();
  }
});

export default router;
