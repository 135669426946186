import axios from 'axios';
import { store } from '@/store';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

const formatErrors = (errors) => {
  const keys = Object.keys(errors);
  let message = '';

  keys.forEach((key) => (message += `${errors[key]} `));

  return message;
};

instance.interceptors.request.use((config) => {
  const token = store.getters['auth/token'];
  config.headers.common.Authorization = `Bearer ${token}`;

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    let formattedError;
    let message;

    if (error.response) {
      const { data, status, statusText } = error.response;
      if (data) {
        message = data.errors ? formatErrors(data.errors) : data.message;
      } else if (
        !data &&
        (error.code === 'ERR_NETWORK' || error.message === 'Network Error')
      ) {
        message = 'Failed to fetch data';
      }

      if (status && status === 403) {
        store.dispatch('loading/toggleLoading', false);
        store.dispatch(
          'notifications/displayNotification',
          {
            message: 'You are not authorized to perform this action, please contact administrator.',
            type: 'danger',
          },
          { root: true }
        );
      } else {
        formattedError = { message, status, statusText };
      }
    } else {
      const { message, status, name: statusText } = error.toJSON();
      formattedError = { message, status, statusText };
    }

    if (formattedError && formattedError.status === 401) {
      store.dispatch('auth/logout');
    }

    return Promise.reject(formattedError);
  }
);

export default instance;
