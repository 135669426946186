import { store } from '@/store';
import { DASHBOARD_PERMISSIONS, COMPANY_PERMISSIONS, CUSTOMER_PERMISSIONS, LOAN_PERMISSIONS, INVESTMENT_PERMISSIONS, STAFF_PERMISSIONS } from '@/constants/permissions';

export const hasPermission = (requiredPermissions) => {

  //return true; // Just uncomment to test 

  const userPermissions = store.getters['auth/currentUser']?.role?.permissions || [];
  
  if (!requiredPermissions) return true;
  
  return Array.isArray(requiredPermissions)
    ? requiredPermissions.some(permission => userPermissions.includes(permission))
    : userPermissions.includes(requiredPermissions);
};


// Helper function to get all permissions
export const getAllPermissions = () => ({
  ...DASHBOARD_PERMISSIONS,
  ...COMPANY_PERMISSIONS,
  ...CUSTOMER_PERMISSIONS,
  ...LOAN_PERMISSIONS,
  ...INVESTMENT_PERMISSIONS,
  ...STAFF_PERMISSIONS
});