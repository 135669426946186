import { investmentServices } from '@/services';
import { getInvestmentTransactions } from '@/services/investmentServices';

const { getInvestments, createInvestment, getInvestmentDetails } =
  investmentServices;

export default {
  namespaced: true,
  state: () => ({
    investments: [],
    reinvestmentProps: {},
  }),
  getters: {
    investments: ({ investments }) => investments,
    reinvestmentProps: ({ reinvestmentProps }) => reinvestmentProps,
  },
  mutations: {
    SET_INVESTMENTS: (state, data) => {
      state.investments = data;
    },
    SET_REINVESTMENT_PROPS: (state, data) => {
      state.reinvestmentProps = data;
    },
  },

  actions: {
    async getAllInvestments({ commit, dispatch }, query) {
      try {
        const { data } = await getInvestments(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || '',
          query?.fromAmount || '',
          query?.toAmount || '',
          query?.maturityStartAt || '',
          query?.maturityEndAt || '',
          query?.createdStartAt || '',
          query?.createdEndAt || '',
          query?.type || '',
          query?.currency || '',
          query?.status || '',
          query?.orderBy || '',
          query?.sortBy || ''
        );
        commit('SET_INVESTMENTS', data.data);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async createNewInvestment({ dispatch }, payload) {
      try {
        const data = await createInvestment(payload);

        if (data.status && data.status === 201) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Investment creation successful', type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getInvestment({ dispatch }, investmentId) {
      try {
        const { data } = await getInvestmentDetails(investmentId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getInvestmentTransactions({ dispatch }, investmentId) {
      try {
        const { data } = await getInvestmentTransactions(investmentId);

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
