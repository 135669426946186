// Dashboard Permissions
export const DASHBOARD_PERMISSIONS = {
  VIEW_DASHBOARD: 'view_dashboard',
  EXPORT_DASHBOARD_REPORTS: 'export_dashboard_reports',
};

// Company Permissions
export const COMPANY_PERMISSIONS = {
  VIEW_ALL_COMPANIES: 'view_all_companies',
  VIEW_COMPANY_PROFILE: 'view_company_profile',
  CREATE_NEW_COMPANY: 'create_new_company',
  VIEW_COMPANY_HISTORY: 'view_company_history',
  VERIFY_COMPANY_KYB: 'verify_company_kyb',
  VIEW_COMPANY_DOCUMENTS: 'view_company_documents',
  ADD_COMPANY_DOCUMENT: 'add_company_document',
  RUN_CREDIT_CHECK: 'run_credit_check',
  VIEW_COMPANY_SETTINGS: 'view_company_settings',
  EDIT_COMPANY_SETTINGS: 'edit_company_settings',
  CHANGE_COMPANY_STATUS: 'change_company_status',
  VIEW_PENDING_APPROVAL_COMPANIES: 'view_pending_approval_companies',
  VIEW_PENDING_COMPANY_HISTORY: 'view_pending_company_history',
  VERIFY_PENDING_COMPANY: 'verify_pending_company',
  VIEW_PENDING_COMPANY_DOCUMENTS: 'view_pending_company_documents',
  ADD_PENDING_COMPANY_DOCUMENT: 'add_pending_company_document',
  RUN_PENDING_CREDIT_CHECK: 'run_pending_credit_check',
  VIEW_PENDING_COMPANY_SETTINGS: 'view_pending_company_settings',
  EDIT_PENDING_COMPANY_SETTINGS: 'edit_pending_company_settings',
  CHANGE_PENDING_COMPANY_STATUS: 'change_pending_company_status',
};

// Customer Permissions
export const CUSTOMER_PERMISSIONS = {
  VIEW_ALL_CUSTOMERS: 'view_all_customers',
  CREATE_CUSTOMER_PROFILE: 'create_customer_profile',
  VIEW_CUSTOMER_PROFILE: 'view_customer_profile',
  ADD_CUSTOMER_DOCUMENT: 'add_customer_document',
  VERIFY_CUSTOMER_KYC: 'verify_customer_kyc',
  VIEW_CUSTOMER_HISTORY: 'view_customer_history',
  EDIT_CUSTOMER_PROFILE: 'edit_customer_profile',
  RESET_CUSTOMER_PASSWORD: 'reset_customer_password',
  SUSPEND_CUSTOMER: 'suspend_customer',
  VIEW_PENDING_VERIFICATION_CUSTOMERS: 'view_pending_verification_customers',
  ADD_PENDING_CUSTOMER_DOCUMENT: 'add_pending_customer_document',
  VERIFY_PENDING_CUSTOMER_BVN: 'verify_pending_customer_bvn',
  VIEW_PENDING_CUSTOMER_HISTORY: 'view_pending_customer_history',
  EDIT_PENDING_CUSTOMER_PROFILE: 'edit_pending_customer_profile',
  RESET_PENDING_CUSTOMER_PASSWORD: 'reset_pending_customer_password',
  SUSPEND_PENDING_CUSTOMER: 'suspend_pending_customer',
};


// Loan  Permissions
export const LOAN_PERMISSIONS = {
  VIEW_ALL_LOANS: 'view_all_loans',
  BOOK_NEW_LOAN: 'book_new_loan',
  VIEW_LOAN_DOCUMENTS: 'view_loan_documents',
  UPLOAD_LOAN_DOCUMENT: 'upload_loan_document',
  VIEW_LOAN_TRANSACTION: 'view_loan_transaction',
  VIEW_LOAN_INSTALLMENTS: 'view_loan_installments',
  VIEW_RISK_PROFILE: 'view_risk_profile',
  EDIT_RISK_PROFILE: 'edit_risk_profile',
  SUBMIT_LOAN_APPLICATION: 'submit_loan_application',
  APPROVE_LOAN: 'approve_loan',
  VIEW_LOANS_AWAITING_ACTION: 'view_loans_awaiting_action',
  BOOK_LOAN_IN_ACTION: 'book_loan_in_action',
  VIEW_OTHER_LOANS: 'view_other_loans',
  CONVERT_LOAN: 'convert_loan',
  DECLINE_LOAN_REQUEST: 'decline_loan_request',
};

//Investment Permissions
export const INVESTMENT_PERMISSIONS = {
  VIEW_ALL_INVESTMENTS: 'view_all_investments',
  VIEW_INVESTMENT_DETAILS: 'view_investment_details',
  PRELIQUIDATE_INVESTMENT: 'preliquidate_investment',
  GENERATE_INVESTMENT_CERTIFICATE: 'generate_investment_certificate',
  OPEN_INVESTMENT_NGN: 'open_investment_ngn',
  OPEN_INVESTMENT_USD: 'open_investment_usd',
  VIEW_INVESTMENT_PAYOUTS: 'view_investment_payouts',
  VIEW_INVESTMENT_TRANSACTIONS: 'view_investment_transactions',
  VIEW_INVESTMENT_DOCUMENTS: 'view_investment_documents',
  ADD_INVESTMENT_DOCUMENT: 'add_investment_document',
};

// Staff Management Permissions
export const STAFF_PERMISSIONS = {
  VIEW_STAFF: 'ViewStaff',
  create_staff_account: 'CreateStaff',
  edit_staff_profile: 'EditStaff',
  suspend_staff_account: 'DeleteStaff',
  MANAGE_ROLES: 'ManageRoles',
};

// Permission Groups
export const PERMISSION_GROUPS = {
  DASHBOARD: 'Dashboard',
  COMPANIES: 'Companies',
  CUSTOMERS: 'Customers',
  LOAN_APPROVAL: 'Loan Approval',
  LOAN_MANAGEMENT: 'Loan Management',
  LOAN_AMENDMENTS: 'Loan Amendments',
  LOAN_EXTENSIONS: 'Loan Extensions',
  LOAN_RESTRUCTURES: 'Loan Restructures',
  STAFF: 'Staff',
};
